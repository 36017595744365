import React from "react"

import { withForgotForm } from "./withForgotForm"
import { StyledInput } from "../../../Styled/Form"
import { Submit, Errors, Error } from "../../Layout/LayoutStyles"

export const ForgotForm = withForgotForm(
  ({
    data,
    handleChange,
    handleSubmit,
    loading,
    success,
    errors,
    additionalSubmitButton,
    additionalEmailPlaceholder,
    additionalSuccessMessage,
  }): JSX.Element => (
    <>
      <form onSubmit={handleSubmit}>
        <StyledInput
          name="email"
          type="email"
          value={data?.email}
          onChange={handleChange}
          placeholder={additionalEmailPlaceholder}
          required
          spacing={"true"}
        />
        {errors?.length > 0 && (
          <Errors>
            {errors?.map((error, index) => (
              <Error key={index}>
                <p>{error?.message}</p>
              </Error>
            ))}
          </Errors>
        )}
        {success ? <Error>{additionalSuccessMessage}</Error> : null}
        <Submit wide={"true"} type={`submit`} disabled={loading}>
          {additionalSubmitButton}
        </Submit>
      </form>
    </>
  )
)
